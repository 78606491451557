import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Subscribe } from 'unstated';

import ImageZoomPopup from 'Component/ImageZoomPopup/';
import { PRODUCT_GALERY_POPUP_ID } from 'Component/ProductGallery/ProductGallery.config';
import {
    mapDispatchToProps,
    mapStateToProps,
    ProductGalleryContainer as SourceProductGalleryContainer,
} from 'Component/ProductGallery/ProductGallery.container';
import SharedTransitionContainer from 'Component/SharedTransition/SharedTransition.unstated';
import ProductListQuery from 'Query/ProductList.query';
import { ProductType } from 'Type/ProductList.type';
import { fetchQuery } from 'Util/Request';

import ArrangementProductGallery from './ArrangementProductGallery.component';

/** @namespace Pwa/Component/ProductGallery/Container/mapStateToProps */

export { mapDispatchToProps, mapStateToProps };

/** @namespace Pwa/Component/ArrangementProductGallery/Container/ArrangementProductGalleryContainer */
export class ArrangementProductGalleryContainer extends SourceProductGalleryContainer {
    static propTypes = {
        ...SourceProductGalleryContainer.propTypes,
        getLink: PropTypes.func.isRequired,
        dataSource: ProductType.isRequired,
        parameters: PropTypes.objectOf(PropTypes.string).isRequired,
        setActiveProduct: PropTypes.func.isRequired,
        quantity: PropTypes.objectOf(PropTypes.number).isRequired,
    };

    __construct(props) {
        super.__construct(props);

        this.state = {
            ...this.state,
            hotspots: {},
            windowWidth: 0,
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();
        this.updateWindowDimensions();
        this.getImageHotspots();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ windowWidth: window.innerWidth });
    }

    containerProps() {
        const {
            getLink,
            dataSource,
            parameters,
            setActiveProduct,
            quantity,
            magentoProduct,
            areDetailsLoaded,
            cartPriceRules,
        } = this.props;
        const { hotspots, windowWidth } = this.state;

        return {
            getLink,
            hotspots,
            dataSource,
            parameters,
            setActiveProduct,
            quantity,
            windowWidth,
            magentoProduct,
            areDetailsLoaded,
            cartPriceRules,
            ...super.containerProps(),
        };
    }

    async getImageHotspots() {
        const [firstPicture] = this.getGalleryPictures() || [];

        if (!firstPicture || !firstPicture?.file) {
            return;
        }

        await fetchQuery(ProductListQuery.getImageHotspots(firstPicture.file)).then(
            /** @namespace Pwa/Component/ArrangementProductGallery/Container/fetchQuery/then */
            (data) => {
                this.setState({ hotspots: { [firstPicture.file]: data.getHotspotsByImagePath } });
            }
        );
    }

    handleImageZoomPopupActiveChange(isImageZoomPopupActive) {
        const { isMobile } = this.props;
        const isHotspotLink = event?.target?.classList?.contains('Hotspot-Link');

        if (isMobile || isHotspotLink) {
            return;
        }

        this.setState({ isImageZoomPopupActive });
    }

    render() {
        const { isImageZoomPopupActive, activeImage } = this.state;

        return (
            <ImageZoomPopup
                isActive={isImageZoomPopupActive}
                onClose={this.handleImageZoomPopupClose}
                activeImageId={activeImage}
                popupId={PRODUCT_GALERY_POPUP_ID}
            >
                <Subscribe to={[SharedTransitionContainer]}>
                    {({ registerSharedElementDestination }) => (
                        <ArrangementProductGallery
                            registerSharedElementDestination={registerSharedElementDestination}
                            {...this.containerProps()}
                            {...this.containerFunctions}
                        />
                    )}
                </Subscribe>
            </ImageZoomPopup>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArrangementProductGalleryContainer);
