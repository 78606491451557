import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { ProductType } from 'Type/ProductList.type';

import ProductSetProductPrice from './ProductSetProductPrice.component';

/** @namespace Pwa/Component/ProductSetProductPrice/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isMobile: state.ConfigReducer.device.isMobile,
    storePhoneNumber: state.ConfigReducer.phone_number,
    installmentsEnabled: state.ConfigReducer.installments_enabled,
});

/** @namespace Pwa/Component/ProductSetProductPrice/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Pwa/Component/ProductSetProductPrice/Container/ProductSetProductPriceContainer */
export class ProductSetProductPriceContainer extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired,
        quantity: PropTypes.objectOf(PropTypes.number).isRequired,
    };

    containerProps() {
        const {
            product,
            quantity,
            isImageZoomPopupActive,
            getActiveProduct,
            activeProduct,
            isMobile,
            storePhoneNumber,
            installmentsEnabled,
        } = this.props;

        return {
            product,
            quantity,
            isImageZoomPopupActive,
            activeProduct,
            getActiveProduct,
            isMobile,
            storePhoneNumber,
            installmentsEnabled,
        };
    }

    render() {
        return <ProductSetProductPrice {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductSetProductPriceContainer);
