import PropTypes from 'prop-types';

import Icons from 'Component/Icons';
import { CarouselScrollArrow as SourceCarouselScrollArrow } from 'SourceComponent/CarouselScrollArrow/CarouselScrollArrow.component';
import { ModsType } from 'Type/Common.type';
import { noopFn } from 'Util/Common';

import './CarouselScrollArrow.style';

/** @namespace Pwa/Component/CarouselScrollArrow/Component/CarouselScrollArrow */
export class CarouselScrollArrow extends SourceCarouselScrollArrow {
    static propTypes = {
        mods: ModsType,
        onClick: PropTypes.func,
        isNextArrow: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        mods: {},
        onClick: noopFn,
        isNextArrow: false,
    };

    render() {
        const { mods, onClick, isNextArrow } = this.props;
        const arrow = isNextArrow ? 'arrow_right' : 'arrow_left';

        return (
            <button block="CarouselScrollArrow" mods={mods} onClick={onClick} aria-label="Arrow">
                <Icons name={arrow} fill="#10069F" />
            </button>
        );
    }
}

export default CarouselScrollArrow;
