import Html from 'Component/Html';
import PRODUCT_TYPE from 'Component/Product/Product.config';
import { ProductActions as SourceProductActions } from 'Component/ProductActions/ProductActions.component';
import { isCrawler, isSSR } from 'Util/Browser';

import './VirtualProductActions.override.style';

/** @namespace Pwa/Component/VirtualProductActions/Component/VirtualProductActions */
export class VirtualProductActions extends SourceProductActions {
    renderAddToCartActionBlock() {
        return (
            <div
                block="VirtualProductActions"
                elem="AddToCartWrapper"
                mods={{ isPrerendered: isSSR() || isCrawler() }}
                mix={{ block: 'ProductActions', elem: 'AddToCartWrapper' }}
            >
                <div
                    block="VirtualProductActions"
                    elem="ButtonsWrapper"
                    mix={{ block: 'ProductActions', elem: 'ButtonsWrapper' }}
                >
                    {this.renderAddToCartButton()}
                </div>
            </div>
        );
    }

    renderShortDescription() {
        const { isVirtualProduct = true, product: { short_description: { html = '' } = {} } = {} } = this.props;

        if (!isVirtualProduct) {
            return;
        }

        return (
            <div block="VirtualProductActions" elem="ShortDescription">
                <Html content={html} />
            </div>
        );
    }

    renderMobile() {
        const {
            product: { type_id: type },
        } = this.props;
        const isWithoutPriceTotal = type === PRODUCT_TYPE.grouped;

        return (
            <>
                <div
                    block="VirtualProductActions"
                    elem="ActionsWrapper"
                    mix={{ block: 'ProductActions', elem: 'ActionsWrapper' }}
                >
                    {this.renderReviewSection()}
                </div>
                <div
                    block="VirtualProductActions"
                    elem="ActionsWrapper"
                    mods={{ isWithoutPriceTotal }}
                    mix={{ block: 'ProductActions', elem: 'ActionsWrapper' }}
                >
                    {this.renderPriceWithGlobalSchema()}
                </div>
                {this.renderDiscount()}
                {this.renderLowestPrice()}
                {this.renderAddToCartActionBlock()}
                {this.renderShortDescription()}
            </>
        );
    }

    renderDesktop() {
        return (
            <>
                {this.renderReviewSection()}
                {this.renderPriceWithGlobalSchema()}
                {this.renderDiscount()}
                {this.renderLowestPrice()}
                {this.renderAddToCartActionBlock()}
                {this.renderShortDescription()}
            </>
        );
    }

    render() {
        const { device: { isMobile } = {}, setValidator } = this.props;

        return (
            <article block="VirtualProductActions" ref={(elem) => setValidator(elem)} mix={{ block: 'ProductActions' }}>
                {isMobile ? this.renderMobile() : this.renderDesktop()}
            </article>
        );
    }
}

export default VirtualProductActions;
