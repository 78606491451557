import { CarouselScrollItem as SourceCarouselScrollItem } from 'SourceComponent/CarouselScrollItem/CarouselScrollItem.component';

/** @namespace Pwa/Component/CarouselScrollItem/Component/CarouselScrollItem */
export class CarouselScrollItem extends SourceCarouselScrollItem {
    render() {
        const { isActive, itemRef, onClick, children } = this.props;

        return (
            <div
                role="button"
                tabIndex={0}
                block="CarouselScrollItem"
                mods={{ isActive }}
                ref={itemRef}
                onClick={onClick}
                onKeyDown={onClick}
                aria-label={__('Thumbnail image')}
            >
                {children}
            </div>
        );
    }
}

export default CarouselScrollItem;
