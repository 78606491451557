/* eslint-disable no-return-assign */
import { withRouter } from 'react-router';
import { TransformWrapper } from 'react-zoom-pan-pinch';

import ArrangementProductPrice from 'Component/ArrangementProductPrice';
import CarouselScroll from 'Component/CarouselScroll';
import Image from 'Component/Image';
import ImageWithHotspots from 'Component/ImageWithHotspots';
import { ProductGallery as SourceProductGallery } from 'Component/ProductGallery/ProductGallery.component';
import ProductGalleryBaseImage from 'Component/ProductGalleryBaseImage';
import ProductSetProductPrice from 'Component/ProductSetProductPrice';
import ProductWishlistButton from 'Component/ProductWishlistButton';
import Slider from 'Component/Slider';
import VideoPopup from 'Component/VideoPopup';
import VirtualProductActions from 'Component/VirtualProductActions';
import { isCrawler } from 'Util/Browser/Browser';

import {
    DEFAULT_SHOWED_ITEMS_COUNT,
    DEFAULT_SHOWED_ITEMS_MARGIN,
    SHOWED_ITEMS_GAP_IN_PIXELS,
    SHOWED_ITEMS_WIDTH_IN_PIXELS,
} from './ArrangementProductGallery.config';

import './ArrangementProductGallery.style';

/** @namespace Pwa/Component/ArrangementProductGallery/Component/ArrangementProductGallery */
export class ArrangementProductGallery extends SourceProductGallery {
    renderWishlistButton() {
        const { magentoProduct } = this.props;

        if (!magentoProduct) {
            return null;
        }

        return (
            <ProductWishlistButton
                magentoProduct={magentoProduct}
                mix={{
                    block: this.className,
                    elem: 'WishListButton',
                }}
            />
        );
    }

    renderActionButtons() {
        const { isMobile } = this.props;

        if (!isMobile) {
            return null;
        }

        return (
            <div block="ProductGallery" elem="ActionButtons">
                {this.renderWishlistButton()}
            </div>
        );
    }

    renderImage(mediaData, index) {
        const {
            activeImage,
            isZoomEnabled,
            handleZoomChange,
            hotspots,
            disableZoom,
            isMobile,
            isImageZoomPopupActive,
            showLoader,
            dataSource,
            sliderRef,
        } = this.props;

        const { scrollEnabled } = this.state;
        const { base: { url: baseSrc } = {}, large: { url: largeSrc } = {}, file } = mediaData;
        const { image, name } = dataSource;
        const isHotspotImage = file === image.path;

        if (isHotspotImage) {
            const src = isImageZoomPopupActive ? largeSrc : baseSrc;
            const portal = sliderRef?.current?.parentNode || sliderRef?.current;

            const hotspotImage = {
                hotspots: hotspots[image.path],
                path: image.path,
                url: src,
            };

            return (
                <ImageWithHotspots
                    image={hotspotImage}
                    label={name}
                    isActiveImage={activeImage === index}
                    portal={portal}
                />
            );
        }

        if (!isMobile) {
            const src = isImageZoomPopupActive ? largeSrc : baseSrc;

            return (
                <Image
                    key={index}
                    src={src}
                    ratio="custom"
                    mix={{
                        block: 'ProductGallery',
                        elem: 'SliderImage',
                        mods: { isPlaceholder: !src, isActive: activeImage === index },
                    }}
                    isPlaceholder={!src}
                    showIsLoading={showLoader}
                    magnification={{ enabled: isImageZoomPopupActive }}
                />
            );
        }

        return (
            <TransformWrapper
                key={index}
                onZoomChange={handleZoomChange}
                onWheelStart={this.onWheelStart}
                onWheel={this.onWheel}
                wheel={{ limitsOnWheel: true, disabled: !scrollEnabled }}
                pan={{
                    disabled: !isZoomEnabled,
                    limitToWrapperBounds: true,
                    velocity: false,
                }}
                options={{
                    limitToBounds: true,
                    minScale: 1,
                }}
            >
                {({ scale, previousScale, resetTransform, setTransform }) => {
                    if (scale === 1 && previousScale !== 1) {
                        resetTransform();
                    }

                    return (
                        <ProductGalleryBaseImage
                            setTransform={setTransform}
                            index={index}
                            mediaData={mediaData}
                            scale={scale}
                            previousScale={previousScale}
                            disableZoom={disableZoom}
                            isZoomEnabled={isZoomEnabled}
                        />
                    );
                }}
            </TransformWrapper>
        );
    }

    renderAdditionalPictures() {
        const { gallery, isImageZoomPopupActive, activeImage, onActiveImageChange, isWithEmptySwitcher, windowWidth } =
            this.props;

        const showedItemWidth = SHOWED_ITEMS_WIDTH_IN_PIXELS + SHOWED_ITEMS_GAP_IN_PIXELS;
        const popUpShowedItemsCount = Math.floor((windowWidth - DEFAULT_SHOWED_ITEMS_MARGIN) / showedItemWidth);
        const showedItemCount = isImageZoomPopupActive ? popUpShowedItemsCount : DEFAULT_SHOWED_ITEMS_COUNT;

        if (gallery.length === 1) {
            return <div block="ProductGallery" elem="Additional" mods={{ isWithEmptySwitcher }} />;
        }

        return (
            <div block="ProductGallery" elem="Additional" mods={{ isImageZoomPopupActive }}>
                <CarouselScroll
                    activeItemId={activeImage}
                    onChange={onActiveImageChange}
                    showedItemCount={showedItemCount}
                    showArrow
                    isImageZoomPopupActive={isImageZoomPopupActive}
                >
                    {gallery.map(this.renderAdditionalPicture.bind(this))}
                </CarouselScroll>
            </div>
        );
    }

    renderSlider() {
        const {
            gallery,
            activeImage,
            isZoomEnabled,
            onActiveImageChange,
            isImageZoomPopupActive,
            sliderRef,
            isMobile,
        } = this.props;

        const mods = {
            isImageZoomPopupActive,
            isZoomInCursor: !isImageZoomPopupActive,
        };

        return (
            <div
                ref={this.imageRef}
                block="ProductGallery"
                elem="SliderWrapper"
                mix={{ block: 'ArrangementProductGallery', elem: 'SliderWrapper' }}
            >
                <meta itemProp="image" content={this.getImageUrl()} />
                {this.renderLabel()}
                <Slider
                    sliderRef={sliderRef}
                    mix={{ block: 'ProductGallery', elem: 'Slider', mods }}
                    showCrumbs={false}
                    showArrows={false}
                    activeImage={activeImage}
                    onActiveImageChange={onActiveImageChange}
                    isInteractionDisabled={isZoomEnabled || isMobile}
                    onClick={this.handleSliderClick}
                    sliderHeight={isImageZoomPopupActive ? '100%' : 0}
                    isHeightTransitionDisabledOnMount
                >
                    {gallery.map(this.renderSlide)}
                </Slider>
            </div>
        );
    }

    renderPriceSection() {
        const {
            dataSource,
            quantity,
            isImageZoomPopupActive,
            product,
            product: { show_as_set = false, is_virtual_design: isVirtualProduct = false },
            getLink,
            areDetailsLoaded,
            setActiveProduct,
            parameters,
            cartPriceRules,
        } = this.props;

        if (isVirtualProduct) {
            return (
                <VirtualProductActions
                    getLink={getLink}
                    product={dataSource}
                    parameters={parameters}
                    areDetailsLoaded={areDetailsLoaded}
                    setActiveProduct={setActiveProduct}
                    cartPriceRules={cartPriceRules}
                    isVirtualProduct={isVirtualProduct}
                />
            );
        }

        if (show_as_set) {
            return (
                <ProductSetProductPrice
                    quantity={quantity}
                    product={dataSource}
                    isImageZoomPopupActive={isImageZoomPopupActive}
                    activeProduct={product}
                />
            );
        }

        return (
            <ArrangementProductPrice
                quantity={quantity}
                product={dataSource}
                isImageZoomPopupActive={isImageZoomPopupActive}
                activeProduct={product}
            />
        );
    }

    renderVideoPopup() {
        if (isCrawler()) {
            return null;
        }

        return <VideoPopup />;
    }

    render() {
        const {
            gallery,
            isMobile,
            device: { isTablet },
        } = this.props;

        const isMoreThanOnePhoto = gallery?.length > 1;

        return (
            <div block="ProductGallery" ref={this.galleryRef} mods={{ isArrangement: true }}>
                {this.renderActionButtons()}
                {this.renderSlider()}
                {!isMobile && !isTablet && this.renderPriceSection()}
                {isMoreThanOnePhoto && this.renderAdditionalPictures()}
                {(isMobile || isTablet) && this.renderPriceSection()}
                {this.renderVideoPopup()}
            </div>
        );
    }
}

export default withRouter(ArrangementProductGallery);
