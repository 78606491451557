import { TransformComponent } from 'react-zoom-pan-pinch';

import Image from 'Component/Image';
import { ProductGalleryBaseImage as SourceProductGalleryBaseImage } from 'SourceComponent/ProductGalleryBaseImage/ProductGalleryBaseImage.component';

/** @namespace Pwa/Component/ProductGalleryBaseImage/Component/ProductGalleryBaseImage */
export class ProductGalleryBaseImage extends SourceProductGalleryBaseImage {
    render() {
        const { src, alt } = this.props;

        return (
            <TransformComponent>
                <Image
                    src={src}
                    ratio="custom"
                    mix={{
                        block: 'ProductGallery',
                        elem: 'SliderImage',
                        mods: { isPlaceholder: !src },
                    }}
                    isPlaceholder={!src}
                    alt={alt}
                    loadingForCrawlers="eager"
                />
                <img style={{ display: 'none' }} alt={alt} src={src} itemProp="image" />
            </TransformComponent>
        );
    }
}

export default ProductGalleryBaseImage;
