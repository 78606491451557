import { ImageZoomPopup as SourceImageZoomPopup } from 'SourceComponent/ImageZoomPopup/ImageZoomPopup.component';

import './ImageZoomPopup.style';

/** @namespace Pwa/Component/ImageZoomPopup/Component/ImageZoomPopup */
export class ImageZoomPopup extends SourceImageZoomPopup {
    render() {
        const { children } = this.props;

        return <div block="ImageZoomPopup">{children}</div>;
    }
}

export default ImageZoomPopup;
