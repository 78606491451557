import { CarouselScrollArrowContainer as SourceCarouselScrollArrowContainer } from 'SourceComponent/CarouselScrollArrow/CarouselScrollArrow.container';

/** @namespace Pwa/Component/CarouselScrollArrow/Container/CarouselScrollArrowContainer */
export class CarouselScrollArrowContainer extends SourceCarouselScrollArrowContainer {
    containerProps() {
        const { isNextArrow, isInvisible } = this.props;

        return {
            isNextArrow,
            mods: {
                isNextArrow,
                isInvisible,
            },
        };
    }
}

export default CarouselScrollArrowContainer;
