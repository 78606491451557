import { Link } from 'react-scroll';

import AddToCart from 'Component/AddToCart';
import { ArrangementProductPrice as SourceProductSetProductPrice } from 'Component/ArrangementProductPrice/ArrangementProductPrice.component';
import Icons from 'Component/Icons';
import { INSTALMENT_CALCULATOR_LINK } from 'Component/ProductActions/ProductActions.config';
import {
    PRODUCT_DETAILS_NAV_DURATION,
    PRODUCT_DETAILS_NAV_OFFSET,
    PRODUCT_DETAILS_NAV_OFFSET_MOBILE,
} from 'Component/ProductDetails/ProductDetails.config';
import ProductReviewRating from 'Component/ProductReviewRating';
import { formatPrice } from 'Util/Price';
import { getBuiltProductsTotals } from 'Util/Product/Extract';

/** @namespace Pwa/Component/ProductSetProductPrice/Component/ProductSetProductPrice */
export class ProductSetProductPrice extends SourceProductSetProductPrice {
    renderRatingSummary() {
        const { product: { rating_summary, review_count } = {} } = this.props;

        if (!rating_summary) {
            return null;
        }

        return <ProductReviewRating summary={rating_summary || 0} count={review_count} />;
    }

    renderProductCounter() {
        const {
            product: { items },
            product,
            quantity,
        } = this.props;

        if (!items) {
            return null;
        }

        const { item_count } = getBuiltProductsTotals({ items, ...product }, quantity);

        return (
            <>
                <div>{__('Products in set')}: </div>
                <div block="Product" elem="PriceWrapper" mods={{ type: 'count' }}>
                    {item_count}
                </div>
            </>
        );
    }

    renderAddToCartButton() {
        const {
            addToCart,
            quantity,
            activeProduct: product,
            activeProduct: { stock_item: { in_stock } = {} } = {},
        } = this.props;

        return (
            <AddToCart
                mix={{ block: this.className, elem: 'AddToCart' }}
                addToCart={addToCart}
                isDisabled={!in_stock}
                isIconEnabled={false}
                quantity={quantity}
                product={product}
                isShortButtonText
                customTexts={{
                    add: __('Add to cart'),
                }}
            />
        );
    }

    renderBuyNowButton() {
        const {
            addToCart,
            quantity,
            activeProduct: product,
            activeProduct: { stock_item: { in_stock } = {} } = {},
        } = this.props;

        return (
            <AddToCart
                block="Button"
                mix={{ block: 'Button', mods: { isHollow: true } }}
                addToCart={addToCart}
                isDisabled={!in_stock}
                isIconEnabled={false}
                quantity={quantity}
                product={product}
                isBuyNowButton
            />
        );
    }

    renderAddToCartActionBlock() {
        return (
            <div block="Product" elem="ButtonsWrapper">
                {this.renderBuyNowButton()}
                {this.renderAddToCartButton()}
            </div>
        );
    }

    renderAskAboutProduct() {
        const { isMobile, storePhoneNumber } = this.props;

        return (
            <div block="Product" elem="AskAboutProduct">
                <div block="Product" elem="AskAboutProductAction">
                    <Link
                        className="Product-AskAboutProductButton"
                        activeClass="active"
                        to="AskAboutProduct"
                        spy
                        smooth
                        duration={PRODUCT_DETAILS_NAV_DURATION}
                        offset={isMobile ? PRODUCT_DETAILS_NAV_OFFSET_MOBILE : PRODUCT_DETAILS_NAV_OFFSET}
                    >
                        <Icons name="envelope" fill="#10069F" />
                        {isMobile ? <span>{__('Ask')}</span> : <span>{__('Ask about product')}</span>}
                    </Link>
                </div>
                {isMobile && (
                    <div block="Product" elem="AskAboutProductAction">
                        <a href={`tel:${storePhoneNumber}`} block="Product" elem="AskAboutProductLink">
                            <Icons name="phone" fill="#10069F" />
                            <span>{__('Call')}</span>
                        </a>
                    </div>
                )}
            </div>
        );
    }

    renderInstalmentsCalculator() {
        const { product: { items } = {}, product, quantity } = this.props;

        if (!items) {
            return null;
        }

        const { final_price } = getBuiltProductsTotals({ items, ...product }, quantity);

        return (
            <a
                href={`${INSTALMENT_CALCULATOR_LINK}&amount=${final_price?.toFixed(2)}`}
                block="Product"
                elem="InstalmentsCalculator"
                target="_blank"
                rel="noreferrer"
                title={__('Calculate instalment')}
            >
                {__('Check!')}
            </a>
        );
    }

    renderInstalments() {
        const { product: { instalment: { value = 0, currency = '' } = {} } = {}, installmentsEnabled } = this.props;

        if (!installmentsEnabled || !value) {
            return null;
        }

        return (
            <div block="Product" elem="InstalmentsWrapper">
                <span>{__('Instalment from')}:</span>
                <span block="Product" elem="InstalmentsData">
                    {formatPrice(value, currency)}
                </span>
                {this.renderInstalmentsCalculator()}
            </div>
        );
    }

    renderProductSetPriceSection() {
        return (
            <>
                {this.renderReviewSection()}
                <div block="Product" elem="PriceSection">
                    {this.renderAvailability()}
                    {this.renderProductCounter()}
                    {this.renderRegularPrice()}
                    {this.renderSpecialPrice()}
                    {this.renderFinalPrice()}
                    {this.renderDiscount()}
                </div>
                {this.renderAddToCartActionBlock()}
                {this.renderAskAboutProduct()}
                {this.renderInstalments()}
            </>
        );
    }

    render() {
        const { isImageZoomPopupActive } = this.props;

        if (isImageZoomPopupActive) {
            return null;
        }

        return (
            <article block="Product" elem="PriceWrapper">
                {this.renderProductSetPriceSection()}
            </article>
        );
    }
}

export default ProductSetProductPrice;
